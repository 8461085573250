import './App.css';


const current = new Date();
const date = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()} ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;

function App() {
  return (
    <div className="App">
      <header className='App-header'> 
        KARDIOID
        <p className='WelcomeMessage'>Welcome to my very sick website!</p>
      </header>
      <div className='Welcome'>
        <div className='WelcomeItem'>
          <p className='WelcomeMeta'>Date:</p>
          <p className='WelcomeValue'> {date}</p>

        </div>
      </div>
      <div className='Shell'>
          <p className = "Name">
          kardioid@kardioid
          </p>
          <p>
            :~$ whoami
          </p>
      </div>

      <div className='Shell'>
          <p className='Printout'>
            Sofware Engineer currently focused on Vehicular Automation & DevOps
          </p>
      </div>

      <div className='Shell'>
          <p className = "Name">
          kardioid@kardioid
          </p>
          <p>
            :~$ ls DomainsOfInterest/
          </p>
      </div>

      <div className='Printout'>
            <p className='Folder'>
            DevOps
            </p>

            <p className='Folder'>
            VehicularAutomation
            </p>

            <p className='Folder'>
            CyberSecurity
            </p>

            <p className='Folder'>
            ComputationalArt
            </p>
      </div>

      <div className='Shell'>
          <p className = "Name">
          kardioid@kardioid
          </p>
          <p>
            :~$ ls LinksToPlatforms/
          </p>
      </div>

      <div className='Printout'>
            <a             
            className="App-link"
            href="https://github.com/WalrusArtist"
            target="_blank"
            rel="noopener noreferrer">
            GitHub
            </a>

            <a             
            className="App-link"
            href="https://www.linkedin.com/in/kardo-marof-09059a157"
            target="_blank"
            rel="noopener noreferrer">
            LinkedIn
            </a>
      </div>

      <div className='Shell'>
          <p className = "Name">
          kardioid@kardioid
          </p>
          <p>
            :~$ ls Contacts/
          </p>
      </div>

      <div className='Printout'>
            <p className='File'>
            kardo009@gmail.com
            </p>

            <p className='File'>
            +46 73 893 0756
            </p>
      </div>
    </div>
  );
}

export default App;
